.map-size {
    width: 650px;
    height: 530px;
}

@media screen and (min-width: 1000px) and (max-width: 1350px){
    .map-size {
        width: 500px;
        height: 530px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1350px){
    .map-size {
        margin-top: 20px;
    }
}

@media screen and (max-width: 800px){
    .map-size {
        margin-top: 40px;
        width: 300px;
        height: 250px;
    }
}
