.product-overview {
    font-family: Arimo;
    width: 100%;
    height: auto;
    font-size: 18px;
    background-color: #F4F4F4;
}

.product-overview-img {
    width: 400px;
    height: 533px;
    cursor: crosshair;
}

.back-button {
    width: 35px;
    height: 35px;
    margin-bottom: 30px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.nazad {
    position: absolute;
    margin-top: 4px;
}

.size-item {
    background-color: white;
    box-shadow: 0 3px 6px 0 grey;
    width: 45px;
    height: 45px;
    margin: 5px;
    padding-top: 11px;
    font-size: 14px !important;
    font-weight: 600;
}

.colorName {
    font-size: 10px;
}

.size-item-product {
    margin: 0px 5px 0px 0px;
}

.text-justify {
    width: 90%;
    text-align: justify;
    text-justify: inter-word;
}

.text-22 {
    font-size: 22px;
}

.text-15 {
    font-size: 15px;
}

.kolicina-input {
    width: 40px;
}

.color-item {
    width: 45px;
    height: 45px;
    margin: 5px;
}

.color-item-product {
    width: 25px;
    height: 25px;
    margin: 5px 5px 5px 0px;
}

figure {
    width: 400px;
    height: 533px;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
}

figure:hover img {
    opacity: 0;
}

.active {
    border: 2px solid black;
}

.hr {
    background-color: black;
    width: 100%;
    float: right;
    height: 0.1em;
}

img {
    display: block;
    width: 100%;
}

.img-item {
    width: 115px;
    height: 153px;
    cursor: pointer;
}

.img-hover-zoom {
    height: 400px;
    overflow: hidden;
    /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.5);
    cursor: crosshair;
}

@media screen and (max-width: 700px) {
    .product-overview-img {
        width: 300px;
        height: 400px;
        margin-right: auto;
        margin-left: 30px;
    }

    figure {
        height: 400px;
    }
}