@font-face {
    font-family: Arimo;
    src: url(../fonts/Arimo-Regular.ttf);
}

@font-face {
    font-family: Syncopat;
    src: url(../fonts/Syncopate-Regular.ttf);
}


@font-face {
    font-family: SyncopatBold;
    src: url(../fonts/Syncopate-Bold.ttf);
}

.lurre-button {
    padding: 10px;
    background-color: black;
    color: white;
    min-width: 100px;
    min-height: 50px;
    border: none;
}

.panel-item {
    overflow: hidden;
    width: 250px;
    height: 300px;
    border: 1px solid grey;
    cursor: pointer;
}

.panel-img {
    margin: 22px 40px 0 40px;
    width: 150px;
    height: 220px;
}

* {
    font-family: Arimo;
}

.ff-syncopate {
    font-family: Syncopat;
}

.ff-syncopate-bold {
    font-family: SyncopatBold;
}

.a:hover {
    color: black;
}

.header-desktop {
    display: block;
}

.header-mobile {
    position: fixed;
    z-index: 2;
    top:0;
    display: none;
    height: 65px;
    width: 100%;
    background-color:black;
}

.header-container {
    height: 70px;
    width: 100%;
    background-color:white;
    color: white;
}

.header-container-lower {
    height: 10px;
    width: 100%;
    background-color:black;
}

.mobile-sub-menu {
    position: fixed;
    top:0;
    /*height: 150%;*/
    width: 0;
    overflow: hidden;
    transition: width ease 0.3s;
    background-color: white;
}

.mobile-sub-menu.show {
    width: 100%; /* viewport width */
    height: 100%; /* viewport height */
    overflow-y: scroll;
    overflow-x: hidden;
}

.sub-menu-item {
    color: black;
}

.sub-menu-item:hover {
    color: #c4bebb;
}

.background-popup {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 50%;
    background-color: black;
}

.popup-delete {
    z-index: 2;
    width: 400px;
    height: 500px;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.mt-50 {
    margin-top: 70px !important;
}



.popup-add-to-cart{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.sub-header-title {
    font-family: Arimo;
    background-color: transparent;
}

.sub-item {
    padding-bottom: 7px;
}

.border-right-black {
    border-right: 1px solid black;
}

.border-right-nav {
    border-right: 1px solid #c4bebb;
}

.border-bottom-nav {
    border-bottom: 1px solid #c4bebb;
}

.subheader-title {
    color: black;
    font-size: 25px;
    font-weight: 700;
}

.sub-header {
    margin-top: 110px;
    width: 1017px;
    position: absolute;
    z-index: 1;
    background-color: white;
}

.page-item {
    z-index: -1;
}

.type-column {
    width: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.type-column-border-left {
    border-left: 2px solid black;
}

.type-column-border-right {
    border-right: 2px solid black;
}

.header-on-mouse-hover {
    height: auto;
    width: 100%;
}

.header-items-left {
    padding-top: 0px;
}

.header-items-middle {
    height: 100%;
    text-align: center;
}

.header-items-right {
    float:right;
}

.logo-item {
    display: inline-block;
    padding-right: 50px;
    font-size: 50px;
    cursor: pointer;
}

.logo-item-2 {
    font-size: 50px;
}

.header-item {
    font-family: Arimo;
    display: inline-block;
    margin: 20px 0px 20px 0px;
    font-size: 20px;
    cursor: pointer;
    color: black;
}

.header-item:hover {
    color: #7a7674;
}

.type-items {
    list-style: none;
    padding:0;
}

.type-item {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding: 0 20px 0 20px;
    font-size: 25px;
    cursor: pointer;
}

.footer-container {
    background-color: black;
}

.text-white {
    color: white;
}

.footer-item {
    font-family: Arimo;
    cursor: pointer;
}

.white {
    background-color: white;
}

.footer-lower {
    height: 40px;
    vertical-align: center;
    background-color: black;
    color: white;
    font-size: 12px;
}

.border-black {
    height: 30px !important;
    width: 1px;
    background-color: black;
}

.filter-boje {
    width: 250px;
    height: 0;
    overflow: hidden;
    transition: height ease 0.3s;
    overflow-x: hidden;
    overflow-y: scroll;
}

.filter-boje::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.filter-boje::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.filter-boje::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 15px;
}

/* Handle on hover */
.filter-boje::-webkit-scrollbar-thumb:hover {
    background: #c4bebb;
}



.filter-boje-title {
    cursor: pointer;
}

.w-30 {
    font-family: Arial;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}

.color-first {
    color: #5c5855;
    font-weight: bold;
}

.color-secondary {
    color: #857e7a;
}

.ff-arimo {
    font-family: Arimo;
}

.ff-arial {
    font-family: Arial, Helvetica, sans-serif;
}

.px40 {
    width: 40px;
    height: 40px;
}

.about-us-logo {
    font-family: Arimo;
    font-size: 1000%;
}

.o-nama-container {
    background-color: white;
}

.font-weight-bold {
    font-weight: bold;
}

.menu-icon {
    width: 30px;
    height: 30px;
}

.w-20 {
    width: 20px;
}

.h-20 {
    height: 20px;
}

.button-active {
    background-color: #c4bebb;
}

.contact-info {
    width: auto;
    height: auto;
    padding: 30px;
    background-color: black;
}

.inline {
    display: inline-block;
}

.border-bottom-black {
    border-bottom: 1px solid #c4bebb;
}

.top-line {
    width: 100%;
    height: 1px;
    background-color: black;
}

Link {
    text-decoration: none;
    text-underline: none;
}

.link {
    text-decoration: underline;
    color: cornflowerblue;
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    display: grid;
    place-items: center;
}

.font-size-16 {
    font-size: 16px;
}

.loader {
    border: 16px solid #e3e3e3;
    border-top: 16px solid #a37155;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

ul li .woman {
    visibility: hidden;
    opacity: 0;
}

ul li:hover .woman {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}

ul li .man {
    visibility: hidden;
    opacity: 0;
}

ul li:hover .man {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}

.buy-btn {
    width: 100px;
    height: 40px;
    cursor: pointer;
}

.cartItem {
    max-width: 1000px;
    background-color: white;
}

.cart-item-img {
    width: 150px;
    height: 200px;
}

.b-width {
    width: 150px;
}

.show-table {
    width: 100%;
    height: 700px;
    overflow-y: scroll;
}

.bg-masa {
    background-color: black;
    color: white;
}

.remove {
    visibility: hidden;
}

.search-img {
    height: 25px;
    width: 25px;
}

.user-icon {
    height: 25px;
    width: 25px;
    float: right;
    margin-right: 25px;
    margin-top: 20px;
}

.search-bar {
    height: 40px;
    background-color: transparent;
}

.search-input {
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}

.search-input:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid black;
}

.mid-container {
    padding-top: 20px;
    border: 1px solid black;
    box-shadow: 0px -20px 30px 5px black;
}

.gender-box {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.sign-me-input{
    width: 30%;
}

.sign-me-input:focus{
    border-color: #c4bebb;
    box-shadow: 0 0 0 3px #c4bebb !important;
}

.sign-me-btn{
    background-color: black;
    border: none;
    font-weight: 700;
    color: white;
    border-radius: 0;
}

.gender-text {
    z-index: 1;
    background-color: black;
    padding: 5px 10px 5px 10px;
    border-radius: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.submenu {
    height: 42px;
    background-color: white;
    width: 100%;
}

.submenu-item {
    width: 500px;
    height: 42px;
    border-top: 1px solid black;
    display: flex;
    justify-content: space-between;
}

.box-img {
    width: 470px;
    height: 650px;
    object-fit: cover;
}

.item-carousel .slick-prev:before {
    color: black;
}
.item-carousel .slick-next:before {
    color: black;
    margin-left: 50px;
}

.item-carousel .slick-prev:hover, .item-carousel .slick-next:hover {
    color: black;
}

.selected-item {
    border: 1px solid black;
}

.cart-color-img {
    max-width: 40px;
    max-height: 40px;
}

.checkout-block {
    flex-direction: column;
    max-width: 350px;
}

.width-350 {
    max-width: 350px;
}

.max-width-300 {
    max-width: 300px;
}

.container {
    max-width: 1400px;
}

.filters {
    height: 1000px;
    min-width: 260px;
}

.cart-items {
    background: #F4F4F4;
    padding-top: 150px;
}

@media screen and (min-width: 1441px) {
.sub-header {
    width: 1417px;
}
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .container {
        max-width: 1000px;
    }
    .filters {
        min-width: 232px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .container {
        max-width: 700px;
    }
    .filters {
        min-width: 162px;
    }
    .panel-item {
        width: 150px;
        height: 200px;
        border: 1px solid grey;
        cursor: pointer;
        padding: 0;
    }
    
    .panel-img {
        margin: 22px 25px 0 25px;
        width: 100px;
        height: 150px;
    }
}

@media screen and (min-width: 720px) and (max-width: 1023px) {
    .container {
        max-width: 400px;
    }
    .filters {
        min-width: 160px;
    }
}

@media screen and (max-width: 700px){

    .cart-items {
        padding-top: 20px;
    }

    .submenu {
        margin-top: 35px;
        padding-top: 20px;
    }

    .shopping-cart-mobile {
        position: fixed;
        bottom: 2%;
        right: 5%;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: black;
    }
    

.box-img {
    width: auto;
    object-fit: cover;
}

    .cartItem {
        max-width: 360px;
    }
    .gender-box {
        width: 360px;
    }

    .about-us-logo {
        font-size: 400%;
        margin-top: 100px;
    }

    .sign-me-input{
        width: 80%;
    }

    .header-items-middle {
        text-align: center;
        padding-top: 0px;
    }

    .header-desktop {
        display: none;
    }

    .header-mobile {
        display: block;
    }

    .logo-item {
        display: inline-block;
        padding-right: 0px;
        padding-top: 10px;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }

    .popup-delete {
        z-index: 2;
        width: 90%;
        height: 80%;
        box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
    }
}