.filter-name {
    font-family: Arimo;
    width: 100%;
    height: 50px;
    font-size: 25px;
    font-weight: 800;
}

.min-height-500{
    min-height: 1200px;
}

.filter-section {
    font-family: Arimo;
    width: 100%;
}

.active-page {
    background-color: black;
    color: white;
}

.color-tamara {
    color: black;
}

.max-width-100 {
    margin-left: auto;
    margin-right: auto;
}

.pagination {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.overflow-x {
    overflow-x: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

@media screen and (min-width: 1440px){
    .pagination {
        width: auto;
        justify-content: center;
    }
}

.color-tamara:hover {
    color: black;
}

.breadcrumb {
    margin-left: 20px !important;
}

.breadcrumb-item {
    text-decoration: none !important;
    color: black;
    cursor: pointer;
}

.breadcrumb-item:hover {
    color: black !important;
}

a {
    text-decoration: none;
    color: black;
}

.clickable {
    cursor: pointer;
}

.sub-section {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height ease 0.3s;
    padding-left: 10px;
}

.sub-section.show {
    height: 200px;
}

.filter-item {
    cursor: pointer;
}

.filter-item:hover {
    color: black;
}

.icon {
    display: inline-block !important;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    display: inline-block !important;
}