.product-info-container {
    margin: 10px;
    padding: 0;
    cursor: pointer;
    width: 471px;
}

.carousel-size {
    max-width: 150px;
    max-height: 200px;
}

.product-info-container:hover {
    box-shadow: 0 0px 6px 0 rgb(0, 0, 0, 0.40);
}

.shadow {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
}

.carousel-img {
    max-width: 300px;
    max-height: 400px;
}

.product {
    position: relative;
}

.discount-p {
    top: 23px;
    right: 0;
    z-index: 6;
    font-size: 20px;
    font-weight: bold;
    color: white;
}

.discount-photo {
    transform: rotate(45deg);
    position: absolute;
    width: 60px;
    height: 60px;
    top: 10px;
    right: -10px;
    z-index: 1;
}

.product-img {
    position: relative;
    max-width: 471px;
    height: 628px;
}

.w-250 {
    width: 250px !important;
}

.velicine {
    position: absolute;
    margin-top: -48px;
    max-height: 50px;
    overflow: hidden;
    color: white;
    width: 471px;
    background-color: black;
}

.product-details {
    overflow: hidden;
}

.price {
    /* position: absolute;
    bottom: 1px;
    right: 1px; */
    font-weight: 600;
    margin-bottom: 0;
    float: right;
}

@media screen and (max-width: 1440px) and (min-width: 701px) {
    .product-img {
        max-width: 300px;
        height: 400px;
    }

    .product-info-container {
        width: 300px;
    }

    .velicine {
        width: 300px;
    }
}

@media screen and (max-width: 700px) {
    .product-info-container {
        margin-left: auto;
        margin-right: auto;
        width: 150px;
    }

    .product-img {
        width: 150px;
        height: 200px;
    }

    .price {
        font-size: 13px;
    }

    .info-text {
        font-size: 12px;
    }
}

@media screen and (max-width: 412px) {
    .product-info-container {
        width: 150px;
        height: 280px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        /*margin: 20px;*/
    }
}