.container-slider {
    max-width: auto;
    /*height: 570px;*/
    height: 800px;
    position: relative;
    z-index: -1;
    overflow: hidden;
  }
  @media screen and (max-width: 700px){
    .container-slider {
      margin: 100px 10px 0;
    }
  }

@media screen and (max-width: 1440px){
  .container-slider {
    height: 470px;
  }
}

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }
  
  .btn-slide img {
    display: none;
    width: 40px;
    height: 40px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #f1f1f1;
    margin: 0 5px;
    background: transparent;
  }
  .dot.active {
    background: #f1f1f1;
  }